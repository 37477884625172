// src/componentes/homePage/HomeCards.jsx
import React from 'react';
import cardIcons from '../../images/h-img.png';

const Card = ({ title, description }) => (
  <div className="relative rounded bg-card-color flex flex-col justify-center items-center align-middle py-14 mb-14">
    <p className="text-2xl font-bold text-gradient">{title}</p>
    <p className="mx-5 text-center text-wrap mt-4 font-semibold text-xl">{description}</p>
    <img src={cardIcons} className="size-20 absolute -bottom-10" alt={title} />
  </div>
);

const HomeCards = () => {
  const cardData = [
    {
      title: 'Profissionalismo',
      description: 'Entregue uma experiência premium à todos os seus clientes.',
    },
    {
      title: 'Escalabilidade',
      description: 'Não fique preso apenas ao seu endereço.',
    },
    {
      title: 'Lucratividade',
      description: 'Aumente a quantidade de clientes.',
    },
    {
      title: 'Investimento',
      description: 'Invista na melhor experiência.',
    },
  ];

  return (
    <div className="mb-20 mx-10">
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5 z-10">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
}

export default HomeCards;
