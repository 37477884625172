import React from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Importando as imagens
import g4Image from '../images/g4.png'; 
import nixusImage from '../images/nixus.png'; 
import ctafile from '../images/ctafile.png'; 

// Componente para exibir uma única caixa de imagem com link
function ImageBox({ imageSrc, backgroundColor, site, small }) {
  return (
    <a
      href={site}
      target="_blank"
      rel="noopener noreferrer"
      className={`flex justify-center items-center  ${
        small ? 'w-56 h-56 sm:w-96 sm:h-96' : 'w-80 h-80'
      }`}
      style={{ backgroundColor }}
    >
      <img
        src={imageSrc}
        alt="Box Content"
        className={`object-contain ${
          small ? 'w-40 h-40' : 'w-28 h-28 hover:w-36 hover:h-36'
        } duration-300`}
      />
    </a>
  );
}

// Componente principal para exibir todas as caixas de imagem
export default function ImageBoxes() {
  const boxes = [
    {
      imageSrc: g4Image,
      backgroundColor: "#000000",
      site: 'https://g4automotivo.com',
    },
    {
      imageSrc: nixusImage,
      backgroundColor: "#202020",
      site: 'https://nixeoseng.com',
    },
    {
      imageSrc: ctafile,
      backgroundColor: "#add8e6",
      site: 'https://ctafilepro.com/index.html',
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="hidden lg:flex justify-center">
        {boxes.map((box, index) => (
          <ImageBox
            key={index}
            imageSrc={box.imageSrc}
            backgroundColor={box.backgroundColor}
            site={box.site}
          />
        ))}
      </div>
      <div className="lg:hidden w-full flex justify-center">
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          autoPlay
          className="w-full max-w-[700px]"
        >
          {boxes.map((box, index) => (
            <div key={index} className="flex justify-center items-center">
              <ImageBox
                small // Indica que deve usar o tamanho pequeno
                imageSrc={box.imageSrc}
                backgroundColor={box.backgroundColor}
                site={box.site}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
