import React from 'react';
import { Link } from 'react-router-dom';
import linkedin from '../images/linkedin.png';
import twitter from '../images/twitter.png';
import facebook from '../images/facebook.png';
import arrow from '../images/Arrow.png';

const Footer = () => {
  return (
    <footer className="bg-header-grey p-7 px-10 md:px-16 text-white">
      <div className="flex font-light lg:flex-row query-ajust:flex-col lg:justify-between query-ajust:justify-center">
        <div className="flex flex-col sm:flex-row justify-center">
          <nav className="mr-24 mb-4 md:mb-0">
            <h3 className="font-bold">Produtos</h3>
            <ul className="mt-3 opacity-95">
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/products">Employee Database</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/products">Payroll</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/products">Absences</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/products">Time Tracking</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/products">Shift Planner</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/products">Recruiting</Link>
              </li>
            </ul>
          </nav>
          <nav className="mr-24 mb-4 md:mb-0">
            <h3 className="font-bold">Informações</h3>
            <ul className="mt-3 opacity-95">
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/faq">FAQ</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/blog">Blog</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/support">Suporte</Link>
              </li>
            </ul>
          </nav>
          <nav>
            <h3 className="font-bold">Empresa</h3>
            <ul className="mt-3 opacity-95">
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/">Home</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/aboutUs">Sobre Nós</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/products">Produtos</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/portfolio">Portfólio</Link>
              </li>
              <li className="opacity-75 hover:opacity-100 duration-500 mt-2">
                <Link to="/contact">Fale Conosco</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="flex lg:flex-row query-ajust:mt-10 lg:mt-0 justify-center">
          <div className="flex flex-col justify-center bg-white bg-opacity-10 p-8 w-full sm:w-[400px] h-auto sm:h-[260px] rounded-md">
            <h3 className="text-center font-bold">Inscreva-se para receber novidades!</h3>
            <div className="flex justify-center items-center">
              <input
                type="email"
                placeholder="E-mail"
                className="text-black p-4 mt-4 rounded-s-md outline-none w-full sm:w-auto"
              />
              <button className="bg-hyla-bg-gradient md:p-[21.5px] query-ajust:p-[23px] mt-4 rounded-e-md">
                <img src={arrow} alt="Arrow" />
              </button>
            </div>
            <p className="text-justify font-extralight text-[14px] opacity-60 mt-6">
              Hello, we are Lift Media. Our goal is to translate the positive effects from revolutionizing how companies engage with their clients & their team.
            </p>
          </div>
        </div>
      </div>
      <hr className="mt-14 h-px my-8 border-0 bg-gray-400 bg-opacity-20" />
      <div className="flex flex-col md:flex-row justify-between items-center p-4">
        <div>
          <h1 className="font-extrabold text-[26px]">HYLA</h1>
        </div>
        <nav className="flex text-center items-center mb-4 md:mb-0 mt-4 md:mt-0">
          <ul className="font-light opacity-80 flex flex-col md:flex-row">
            <li className="opacity-75 hover:opacity-100 duration-500 mb-2 md:mb-0 md:mr-6">
              <Link to="/terms">Termos de Uso</Link>
            </li>
            <li className="opacity-75 hover:opacity-100 duration-500 mb-2 md:mb-0 md:mr-6">
              <Link to="/privacy">Políticas de Privacidade</Link>
            </li>
            <li className="opacity-75 hover:opacity-100 duration-500 mb-2 md:mb-0">
              <Link to="/cookies">Cookies</Link>
            </li>
          </ul>
        </nav>
        <div className="flex items-center">
          <div className="mr-4">
            <a href="#">
              <img
                className="size-7 flex items-center opacity-85 hover:opacity-100 duration-500"
                src={linkedin}
                alt="LinkedIn"
              />
            </a>
          </div>
          <div className="mr-4">
            <a href="#">
              <img
                className="size-7 flex items-center opacity-85 hover:opacity-100 duration-500"
                src={facebook}
                alt="Facebook"
              />
            </a>
          </div>
          <div className="mr-4">
            <a href="#">
              <img
                className="size-7 flex items-center opacity-85 hover:opacity-100 duration-500"
                src={twitter}
                alt="Twitter"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
