import React from 'react';
import Header from "../../componentes/Header";
import HomeCards from "../../componentes/homePage/HomeCards";
import ClientTestimonials from "../../componentes/ClientTestimonials";
import goToIcon from '../../images/go-to-icon.png'; 

import ImageSlider from '../../componentes/homePage/ImageSlider';
import Footer from '../../componentes/Footer';

const Home = () => {
  return (
    <>
      <Header />

      <section className="bg-main-page pt-24 border-b border-gradient">
            <div className="m-5">
                <div className="md:mx-32 query-ajust:mx-5">
                    <p className="font-bold">Desde 2024</p>
                    <p className="lg:text-[80px] md:text-[50px] query-ajust:text-[35px] font-bold mt-3 lg:max-w-[70%] md::max-w-[85%] query-ajust:mx-w-[80%">
                        Revolucionando Empreendimentos com
                    </p>
                    <p className="text-gradient lg:text-[80px] md:text-[50px] query-ajust:text-[40px] font-bold pb-8">
                        Tecnologia de ponta.
                    </p>
                    <div className="pb-6">
                        <button className="text-gradient font-bold py-2 px-4 border-2 border-gradient flex items-center">
                            Comece Agora
                            <img
                                src={goToIcon}
                                className="ml-3 w-7 h-7"
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </div>
        </section>
      
      <main className="mt-32">

        {/* Cards */}
        <HomeCards />

        {/* Clientes */}
        <ClientTestimonials />

        {/* Image Slider */}
        <div className='mb-32'>
          <ImageSlider />
        </div>

        {/* Ficou Interessado */}
        <div className="flex flex-col justify-center items-center mb-10">
          <p className="font-bold text-2xl">
            Ficou <span className="text-gradient">Interessado</span>?
          </p>
          <p className="my-9 flex justify-center text-center">
            Entre em contato para saber mais como podemos transformar seu empreendimento.
          </p>
          <button className="ml-5 text-2xl text-gradient font-semibold py-4 px-16 border-2 border-gradient flex">
            Comece Agora
            <img src={goToIcon} className="ml-3 size-8" alt="Go to icon" />
          </button>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default Home;
