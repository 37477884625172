import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Portfolio from "./pages/portfolio";
import AboutUs from "./pages/aboutUs";
import Contact from "./pages/contact";
import Products from "./pages/products";

function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/portfolio" element={<Portfolio />}></Route>
                <Route path="/aboutUs" element={<AboutUs />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/products" element={<Products />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;