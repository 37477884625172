import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules'; // Importe os módulos de navegação e paginação
import 'swiper/css/pagination'; // Importe o CSS para a paginação
import 'swiper/css';
import 'swiper/css/navigation'; // Importe o CSS para a navegação
import chibaImage from '../images/chiba.png';
import brazilFlag from '../images/brazilFlag.png';
import usaFlag from '../images/usaFlag.png';




const clientData = [
  {
    name: 'Larissa Sayuri Chiba',
    position: 'CEO & Fundadora da NIXEOS',
    testimonial: 'A HYLA desenvolveu um website incrível para a NIXEOS, superando nossas expectativas com criatividade e funcionalidade. O processo foi fluido, desde a primeira reunião até a entrega. O impacto nos nossos negócios foi significativo, e o suporte foi excelente. Recomendo a HYLA por sua confiabilidade e dedicação em entregar resultados excepcionais.',
    imageUrl: chibaImage,
    flag: brazilFlag,
  },
  {
    name: 'Larissa Sayuri Chiba',
    position: 'CEO & Fundadora da NIXEOS',
    testimonial: 'A HYLA desenvolveu um website incrível para a NIXEOS, superando nossas expectativas com criatividade e funcionalidade. O processo foi fluido, desde a primeira reunião até a entrega. O impacto nos nossos negócios foi significativo, e o suporte foi excelente. Recomendo a HYLA por sua confiabilidade e dedicação em entregar resultados excepcionais.',
    imageUrl: chibaImage,
    flag: brazilFlag,
  },
  {
    name: 'Larissa Sayuri Chiba',
    position: 'CEO & Fundadora da NIXEOS',
    testimonial: 'A HYLA desenvolveu um website incrível para a NIXEOS, superando nossas expectativas com criatividade e funcionalidade. O processo foi fluido, desde a primeira reunião até a entrega. O impacto nos nossos negócios foi significativo, e o suporte foi excelente. Recomendo a HYLA por sua confiabilidade e dedicação em entregar resultados excepcionais.',
    imageUrl: chibaImage,
    flag: usaFlag,
  },
];

const ClientTestimonials = () => (
  <div className="flex flex-col justify-center items-center my-32 md:mx-28 query-ajust:mx-7">
    <p className="font-bold text-2xl mb-10 text-center">
      O que nossos <span className="text-gradient">clientes</span> estão dizendo?
    </p>
    <Swiper
      modules={[Navigation, Pagination]} // Use os módulos de navegação e paginação
      spaceBetween={30}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 3000 }}
      pagination={{ clickable: true }} // Configuração simples para paginação
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      className="mx-8 mb-10 w-full lg:w-3/4"
    >
      {clientData.map((client, index) => (
        <SwiperSlide key={index}>
          <div className="p-6 bg-white border-2 rounded-lg shadow-lg md:mx-12">
            <p className="text-justify mb-8 mx-5">
              {client.testimonial}
            </p>
            <div className="flex items-center mx-5">
              <img src={client.imageUrl} className="size-12 rounded-full" alt={client.name} />
              <div className="ml-3 mb-5">
                <div className='flex flex-row items-center'>
                  <p className="font-bold">{client.name} </p>
                  <img src={client.flag} className="size-3 w-fit ml-2" />
                </div>
                <p>{client.position}</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
      {/* Botões de navegação */}
      <div className='query-ajust:hidden md:block'>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </Swiper>
  </div>
);

export default ClientTestimonials;
