import Footer from "../../componentes/Footer";
import Header from "../../componentes/Header";

const Contact = () => {
    return (
        <>
            <Header />

            <div id="faleconosco">
                {/* Cabeçalho */}
                <div className="bg-main-page border-b border-gradient flex flex-row justify-center">
                    <div className="m-5 w-full">
                        <div className="md:mx-32 query-ajust:mx-5 pt-16 md:pt-36 pb-24 md:pb-20">
                            <p className="lg:text-[35px] md:text-[30px] text-[25px] font-bold mt-3 text-center">
                                Entre em <span className="text-gradient">contato</span> com a gente.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Seção de Formulário e Informações */}
                <div className="mt-16 md:mt-20 mb-16 lg:mb-36 mx-5 xl:mx-20 flex flex-col xl:flex-row justify-evenly items-center">
                    <div className=" lg:w-auto mb-10 lg:mb-0">
                        <h2 className="text-[25px] font-bold">Entre em contato com a gente.</h2>
                        <p className="text-[18px] font-medium">
                            Ou entre em contato através de: <span className="text-[#009FFF]">contato@hyla.com.br</span>
                        </p>
                        <div className="mt-7">
                            <form className="text-black">
                                {/* Inputs de Nome e E-mail */}
                                <div className="flex flex-col md:flex-row md:space-x-4">
                                    <div className="md:mr-[17px] mb-4 md:mb-0">
                                        <label htmlFor="nome"></label>
                                        <input
                                            placeholder="Nome"
                                            type="text"
                                            id="nome"
                                            className="outline-none border-[2px] border-gradient p-2 w-full md:w-[300px]"
                                        />
                                    </div>
                                    <div>
                                        <input
                                            placeholder="E-mail"
                                            type="email"
                                            id="email"
                                            className="outline-none border-[2px] border-gradient p-2 w-full md:w-[300px]"
                                        />
                                    </div>
                                </div>

                                {/* Input de Assunto */}
                                <div className="mt-4">
                                    <input
                                        placeholder="Assunto"
                                        type="text"
                                        id="assunto"
                                        className="outline-none border-[2px] border-gradient p-2 w-full md:w-[632px]"
                                    />
                                </div>

                                {/* Textarea de Mensagem */}
                                <div className="mt-4">
                                    <textarea
                                        placeholder="Mensagem"
                                        id="mensagem"
                                        rows="4"
                                        className="outline-none border-[2px] border-gradient resize-none p-2 w-full md:w-[632px]"
                                    ></textarea>
                                </div>

                                {/* Botão de Enviar */}
                                <button
                                    type="submit"
                                    className="mt-2 rounded-[0.20rem] bg-hyla-bg-gradient text-white font-medium py-2 w-full md:w-[632px]"
                                >
                                    Enviar
                                </button>
                            </form>
                        </div>
                    </div>

                    {/* Seção de Imagem ou Gráfico */}
                    <div className="bg-main-page w-[200px] h-[200px] mt-11 md:w-[365px] md:h-[365px]"></div>
                </div>
            </div>

            <Footer />
        </>

    );
};

export default Contact;
