import React from 'react';
import Header from "../../componentes/Header";
import ClientTestimonials from "../../componentes/ClientTestimonials";
import ImageBoxes from '../../componentes/ImageBoxes';
import goToIcon from '../../images/go-to-icon.png';
import Footer from '../../componentes/Footer';




const Portfolio = () => {
  return (
    <>
      <Header />
      <main>
        <section className="bg-main-page pt-24 border-b border-gradient flex flex-row lg:block query-ajust:justify-center">
          <div className="m-5">
            <div className="md:mx-32 query-ajust:mx-5 py-6">
              <p className="lg:text-[80px] md:text-[50px] query-ajust:text-[35px] font-bold mt-3">
                Conheça nossos
              </p>
              <p className="lg:text-[80px] md:text-[50px] query-ajust:text-[40px] font-bold pb-8">
                <span className='text-gradient'>Projetos.</span>
              </p>
            </div>
          </div>
        </section>

      </main>

      <div className='my-10'>
        <ImageBoxes />
      </div>

      <ClientTestimonials />


      {/* Ficou Interessado */}
      <div className="flex flex-col justify-center items-center mb-10">
        <p className="font-bold text-2xl text-center">
          Gostou das nossas<span className="text-gradient"> revoluções?</span>
        </p>
        <p className="my-9 flex justify-center text-center">
          Entre em contato para saber mais sobre como podemos transformar seu empreendimento.
        </p>
        <button className="ml-5 text-2xl text-gradient font-semibold py-4 md:px-16 query-ajust:px-8 border-2 border-gradient flex">
          Entre em Contato
          <img src={goToIcon} className="ml-3 size-8" alt="Go to icon" />
        </button>
      </div>

      <Footer />
    </>
  );
}

export default Portfolio;
