import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/navigation';
import { FaLinkedin, FaInstagram, FaGithub, FaBehance } from 'react-icons/fa';
import profileImage from '../images/image.png'; // Replace with the actual image
import cardIcons from '../images/h-img.png';


const founderData = [
  {
    name: 'Lucas Lima',
    position: 'Fundador & CEO da HYLA, Diretor Comercial e Financeiro.',
    imageUrl: profileImage,
    socialLinks: [
      { icon: FaLinkedin, url: 'https://linkedin.com' },
      { icon: FaInstagram, url: 'https://instagram.com' },
      { icon: FaGithub, url: 'https://github.com' },
      { icon: FaBehance, url: 'https://behance.net' },
    ],
  },
  {
    name: 'Lucas Lima',
    position: 'Fundador & CEO da HYLA, Diretor Comercial e Financeiro.',
    imageUrl: profileImage,
    socialLinks: [
      { icon: FaLinkedin, url: 'https://linkedin.com' },
      { icon: FaInstagram, url: 'https://instagram.com' },
      { icon: FaGithub, url: 'https://github.com' },
      { icon: FaBehance, url: 'https://behance.net' },
    ],
  },
  {
    name: 'Lucas Lima',
    position: 'Fundador & CEO da HYLA, Diretor Comercial e Financeiro.',
    imageUrl: profileImage,
    socialLinks: [
      { icon: FaLinkedin, url: 'https://linkedin.com' },
      { icon: FaInstagram, url: 'https://instagram.com' },
      { icon: FaGithub, url: 'https://github.com' },
      { icon: FaBehance, url: 'https://behance.net' },
    ],
  },
  {
    name: 'Lucas Lima',
    position: 'Fundador & CEO da HYLA, Diretor Comercial e Financeiro.',
    imageUrl: profileImage,
    socialLinks: [
      { icon: FaLinkedin, url: 'https://linkedin.com' },
      { icon: FaInstagram, url: 'https://instagram.com' },
      { icon: FaGithub, url: 'https://github.com' },
      { icon: FaBehance, url: 'https://behance.net' },
    ],
  },
];

const FoundersSwiper = () => (
  <div className="flex flex-col justify-center items-center">
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={30}
      slidesPerView={1}
      loop={true}
      pagination={{ clickable: true }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      className="w-full max-w-lg"
    >
      {founderData.map((founder, index) => (
        <SwiperSlide key={index}>
          <div className="bg-white border-2 rounded-lg shadow-lg pb-8 mx-14">
              <div className="relative">
                <img src={founder.imageUrl} alt={founder.name} className="w-full h-48 rounded-t-lg object-cover" />
                <div className="absolute -bottom-1 z-10 right-14 w-fit p-10 transform translate-x-1/2 translate-y-1/2">
                  <img src={cardIcons} className="size-20 absolute bottom-0 right-0 w-fit" />
                </div>
              </div>
            <div className='mx-10'>
              <div className="text-start mt-6">
                <div>
                  <h3 className="text-xl font-bold">{founder.name}</h3>
                  <p className="text-gray-600">{founder.position}</p>
                </div>
                <div className="flex justify-start space-x-4 my-4 ">
                  {founder.socialLinks.map((link, i) => (
                    <a href={link.url} key={i} target="_blank" rel="noopener noreferrer" className="text-2xl text-gray-700">
                      <link.icon />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
      <div className="hidden md:block">
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </Swiper>
  </div>
);

export default FoundersSwiper;
