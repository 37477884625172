import React, { useEffect, useRef, useState } from 'react';
import g4Image from '../../images/g4.png'; 
import nixusImage from '../../images/nixus.png'; 
import ctafile from '../../images/ctafile.png'; 

const ImageSlider = () => {
  const sliderRef = useRef(null);
  const [repeatedImages, setRepeatedImages] = useState([]);

  useEffect(() => {
    const slider = sliderRef.current;
    let animationId;

    const startAnimation = () => {
      slider.scrollLeft -= 0.1; // Move o slider para a esquerda
      if (slider.scrollLeft <= 0) {
        slider.scrollLeft = slider.scrollWidth; // Reinicia a rolagem quando atingir o início
      }
      animationId = requestAnimationFrame(startAnimation);
    };

    startAnimation(); // Inicia a animação

    return () => {
      cancelAnimationFrame(animationId); // Cancela a animação quando o componente desmontar
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const images = [g4Image, nixusImage, ctafile];
      const numberOfRepeats = Math.ceil(window.innerWidth / (100 + 4)) * 3; // Ajusta o número de repetições
      setRepeatedImages(Array(numberOfRepeats).fill(images).flat());
    };

    handleResize(); // Inicializa as imagens repetidas
    window.addEventListener('resize', handleResize); // Atualiza ao redimensionar a tela

    return () => {
      window.removeEventListener('resize', handleResize); // Limpa o listener quando o componente desmontar
    };
  }, []);

  return (
    <div className="overflow-hidden whitespace-nowrap" ref={sliderRef} style={{ backgroundColor: '#000000', width: '100%' }}>
      {repeatedImages.map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`image-${index}`}
          className="inline-block mr-12"
          style={{ width: '100px', height: '100px', objectFit: 'contain' }}
        />
      ))}
    </div>
  );
};

export default ImageSlider;
