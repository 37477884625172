import React from 'react';
import Header from "../../componentes/Header";
import ClientTestimonials from "../../componentes/ClientTestimonials";
import ImageBoxes from '../../componentes/ImageBoxes';
import goToIcon from '../../images/go-to-icon.png';
import FoundersSwiper from '../../componentes/Founders';
import Footer from '../../componentes/Footer';

const AboutUs = () => {
    const sliderValue = 200; // Defina o valor do slider programaticamente

    return (
        <>
            <Header />
            <main>
                <section className="bg-main-page pt-12 border-b border-gradient flex flex-row justify-center">
                    <div className="m-5">
                        <div className="md:mx-32 query-ajust:mx-5 py-20">
                            <p className="lg:text-[35px] md:text-[30px] query-ajust:text-[25px] font-bold mt-3 text-center">
                                Somos a <span className='text-gradient'>Evolução</span> do seu Empreendimento
                            </p>
                        </div>
                    </div>
                </section>
            </main>


            {/*Nós*/}
            <div className='my-20 grid lg:grid-cols-2 query-ajust:grid-cols-1 md:mx-40 '>
                <div>
                    <div className='flex flex-col justify-center lg:mx-5'>
                        <h2 className='text-[32px] font-bold'>Como tudo começou</h2>
                        <div className='text-justify text-[16px] opacity-80 mt-5 mb-20'>
                            <p>Tudo teve início quando o desejo pela prosperidade floresceu dentro de quatro amigos, sendo eles Lucas Henrique Lima, Yuri Chiba, Luis Martins e Aléxia Reis, e das iniciais surgiu o nome HYLA. Deles, nasceu a ideia de impactar positivamente o crescimento dos que compartilham desse desejo, e crescer com a evolução dos outros. </p>
                            <p className='my-3'>Com isso, as aptidões de todos unidos e enxergando uma peça faltante no quebra-cabeças, tudo apontou para ajudar a solucionar o problema daqueles que precisam se encaixar na era em que vivem, a Era Digital. </p>
                            <p>Tendo isso em vista, várias necessidades e soluções que poderiam auxiliar e alavancar o crescimento de negócios foram ficando cada vez mais claras, foram sendo moldados produtos e serviços que se encaixavam em cada empreendimento, fazendo-o prosperar cada vez mais.   </p>

                        </div>
                    </div>

                </div>
                <FoundersSwiper />
            </div>


            <section className="bg-main-page flex flex-row justify-center mb-20">
                <div className="m-5">
                    <div className="md:mx-32 query-ajust:mx-5 py-20">
                        <p className="text-[25px] font-bold mt-3 text-center">
                            A <span className='text-gradient'>HYLA</span> nasce da <span className='text-gradient'>Visão e Propósito</span> dos fundadores.
                        </p>

                        <p className="mt-10 text-justify">
                            O propósito da HYLA é servir de combustível para evolução de negócios, visando propulsionar o mercado digital do Brasil, multiplicando empregos e potencializando a economia.
                        </p>
                        <p className="mt-3 text-justify">
                            Através de serviços, produtos e recursos tecnológicos, transformaremos a realidade do mercado brasileiro dentro da internet.
                        </p>
                    </div>
                </div>
            </section>

            {/* Ficou Interessado */}
            <div className="flex flex-col justify-center items-center my-10">
                <p className="font-bold text-2xl text-center">
                    Qual é <span className="text-gradient">Principal Objetivo </span>
                    da <span className="text-gradient">HYLA</span>?
                </p>
                <p className="my-9 flex justify-center text-center">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                </p>

                {/* Slider não interativo */}
                <div className="flex items-center justify-center w-[70%]">
                    <span>0</span>
                    <div
                        className="mx-4 w-full h-2 bg-gray-200 rounded-lg"
                        style={{
                            background: `linear-gradient(to right, #6b21a8 0%, #14b8a6 ${sliderValue / 10}%, #e5e7eb ${sliderValue / 10}%, #e5e7eb 100%)`,
                        }}
                    />
                    <span>1000</span>
                </div>
            </div>
            <Footer /> 
        </>
    );
}

export default AboutUs;
