import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ServiceCard from '../../componentes/ServiceCard';
// Importe as imagens
import landingPageImg from '../../images/LandingPage.png';
import cardapioDigitalImg from '../../images/CardapioDigital.png';
import gestaoTrafegoImg from '../../images/GestaoTrafego.png';
import designGraficoImg from '../../images/DesignGrafico.png';
import automatizacaoAtendimentoImg from '../../images/Automatizacao.png';
import socialMediaImg from '../../images/SocialMedia.png';
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';

// Constantes dos cards de serviço
const serviceCards = [
    {
        title: "Landing Page",
        price: "A partir de R$800,00",
        imageSrc: landingPageImg,
        gradientFrom: "hyla-card-purple-1",
        gradientTo: "hyla-card-purple-2",
    },
    {
        title: "Cardápio Digital",
        price: "A partir de R$600,00",
        imageSrc: cardapioDigitalImg,
        gradientFrom: "hyla-card-green-1",
        gradientTo: "hyla-card-green-2",
    },
    {
        title: "Gestão de Tráfego",
        price: "A partir de R$800,00",
        imageSrc: gestaoTrafegoImg,
        gradientFrom: "hyla-card-blue-1",
        gradientTo: "hyla-card-blue-2",
    },
    {
        title: "Design Gráfico",
        price: "A partir de R$600,00",
        imageSrc: designGraficoImg,
        gradientFrom: "hyla-card-purple-1",
        gradientTo: "hyla-card-purple-2",
    },
    {
        title: "Automatiação de Atendimento",
        price: "A partir de R$300,00",
        imageSrc: automatizacaoAtendimentoImg,
        gradientFrom: "hyla-card-green-1",
        gradientTo: "hyla-card-green-2",
    },
    {
        title: "Social Media",
        price: "A partir de R$200,00",
        imageSrc: socialMediaImg,
        gradientFrom: "hyla-card-blue-1",
        gradientTo: "hyla-card-blue-2",
    },
];

export default function Products() {
    return (
        <>
            <Header />
            <div id="produtos">
                {/* Cabeçalho */}
                <div className="bg-main-page border-b border-gradient flex flex-row justify-center">
                    <div className="m-5 w-full">
                        <div className="md:mx-32 query-ajust:mx-5 pt-28 pb-16 query-ajust::py-20">
                            <p className="lg:text-[35px] md:text-[30px] text-[25px] font-bold mt-3 text-center">
                                Eleve o Nível da sua <span className="text-gradient">Empresa.</span>
                            </p>
                        </div>
                    </div>
                </div>

                {/* Serviços */}
                <div className="container mx-auto px-4">
                    <div className="flex flex-row justify-center">
                        <p className="mt-9 mb-9 font-extrabold text-center text-[28px]">
                            Conheça nossos<span className="text-gradient"> serviços:</span>
                        </p>
                    </div>

                    {/* Grid para telas grandes */}
                    <div className="hidden lg:flex justify-center flex-wrap gap-6 mb-9">
                        {serviceCards.map((card, index) => (
                            <ServiceCard
                                key={index}
                                title={card.title}
                                price={card.price}
                                imageSrc={card.imageSrc}
                                gradientFrom={card.gradientFrom}
                                gradientTo={card.gradientTo}
                            />
                        ))}
                    </div>

                    {/* Carrossel para telas menores */}
                    <div className="lg:hidden flex justify-center">
                        <Carousel
                            showThumbs={false}
                            showStatus={false}
                            infiniteLoop
                            autoPlay
                            className="min-w-[300px] "
                        >
                            {serviceCards.map((card, index) => (
                                <div key={index} className="flex justify-center items-center">
                                    <ServiceCard
                                        title={card.title}
                                        price={card.price}
                                        imageSrc={card.imageSrc}
                                        gradientFrom={card.gradientFrom}
                                        gradientTo={card.gradientTo}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className='mt-10'>
                <Footer />
            </div>
        </>
    );
}
