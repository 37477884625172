import React from 'react';

const ServiceCard = ({ title, price, imageSrc, gradientFrom, gradientTo }) => {
    return (
        <div
            className={`bg-gradient-to-b from-${gradientFrom} to-${gradientTo} rounded-lg shadow-lg w-full sm:w-[19rem] h-[22rem] p-4 text-white relative`}>
            <div>
                <h2 className="text-lg font-bold mb-2 flex justify-start">{title}</h2>
                <p className="text-sm flex justify-start">{price}</p>
            </div>
            <img src={imageSrc} className="absolute bottom-0 right-0" alt={title} />
        </div>
    );
};

export default ServiceCard;
