import { useState } from 'react';
import darkPage from '../images/dark-mode-icon.png';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="bg-header-grey md:pr-20 md:pl-28 query-ajust:px-4 fixed z-50 top-0 left-0 right-0">
            <div className="flex items-center bg-header-grey query-ajust:py-5 md:py-2 px-6 lg:justify-between query-ajust:justify-end ">

                {/* Logo */}
                <div className="lg:m-3 query-ajust:mr-auto">
                    <Link to={"/"}>
                        <a className="text-white text-gradient font-bold font-poppins text-2xl">HYLA</a>
                    </Link>
                </div>

                {/* Dropdown Btn */}
                <div className="block lg:hidden order-2 md:order-2 sm:order-2 items-center bg-header-grey align-middle">
                    <button onClick={toggleMenu} className="text-white focus:outline-none">
                        <svg className="w-6 h-6 mt-2 bg-header-grey" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                </div>

                <div className="query-ajust:hidden lg:w-auto lg:flex lg:items-center bg-black-text ">
                    <nav className="bg-header-grey">
                        <Link to={"/"}>
                            <a className="bg-black-text text-sm font-bold hover:text-g4-orange py-2 link link-underline link-underline-black"
                                href="index.html">Home</a>
                        </Link>
                        <Link to={"/portfolio"}>
                            <a className="mx-5 bg-black-text text-sm font-bold hover:text-g4-orange py-2 link link-underline link-underline-black"
                                href="portifólio.html">Portifólio</a>
                        </Link>
                        <Link to={"/products"}>
                            <a className="mr-5 bg-black-text text-sm font-bold hover:text-g4-orange py-2 link link-underline link-underline-black"
                                href="produtos.html">Produtos</a>
                        </Link>
                        <Link to={"/aboutUs"}>
                            <a className="bg-black-text text-sm font-bold hover:text-g4-orange py-2 link link-underline link-underline-black"
                                href="aboutUs.html">Sobre Nós</a>
                        </Link>
                    </nav>
                </div>

                {/* Right Header */}
                <div className="m-4 flex flex-row align-middle items-center">
                    <Link to={"/contact"}>
                        <button
                            className="mr-3 hidden md:block lg:block xl:block 2xl:block bg-hyla-gradient font-semibold font-poppins text-white py-3 px-7 rounded-link text-[0.8rem]">Fale
                            Conosco</button>
                    </Link>
                    {/* <button className="mr-3" href="">
                        <img src={darkPage} style={{ width: '2.4rem' }} alt="Dark Mode" />
                    </button> */}

                </div>

            </div>
            {/* Nav in Dropdown */}
            <div id="menu" className={`menu pb-5 bg-header-grey mx-7 mt-3 ${isMenuOpen ? '' : 'hidden'}`}>
                <Link to={"/"}>
                    <a className="bg-black-text block hover:text-hyla-blue duration-300 mb-4" href="#">Home</a>
                </Link>
                <Link to={"/portfolio"}>
                    <a className="bg-black-text block hover:text-hyla-blue duration-300 mb-4" href="#">Portifólio</a>
                </Link>
                <Link to={"/products"}>
                    <a className="bg-black-text block hover:text-hyla-blue duration-300 mb-4" href="#">Produtos</a>
                </Link>
                <Link to={"/aboutUs"}>
                    <a className="bg-black-text block hover:text-hyla-blue duration-300 mb-4" href="#">Sobre Nós</a>
                </Link>
                <Link to={"/contact"}>
                    <a className="bg-black-text block hover:text-hyla-blue duration-300 mb-4" href="#">Fale Conosco</a>
                </Link>
            </div>
        </div>
    )
}

export default Header;
